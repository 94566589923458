/**
 * Lausd.Facilities.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SstFavorite } from "./sstFavorite";


export interface MdlSstSchoolInfo {
  enrollmentNotes?: string;
  favoriteDetails?: SstFavorite;
  costCenter?: string;
  schoolName?: string;
  document?: SchoolInfoDocument;
  score?: number;
  maxScore?: number;
  version?: number;
  distance?: number;
  rank?: number;
  isResidential?: boolean;
  isZoc?: boolean;
  locationCode?: string;
  isFavorite?: boolean;
}
export interface SchoolInfoDocument {
  sst: {
    address: string,
    addressCity: string,
    addressZip: number,
    addressState: string,
    addressStreet1: string,
    artsProgramsSec: string,
    acs_schools: string,
    afc_schools: string,
    avidSchools: string,
    avidGrade: string,
    awardBestHigh: string,
    awardBlueRibbon: string
    awardCaDecathlon: string,
    awardCyberPatriot: string,
    awardGoldRibbon: string,
    awardMagnetAmerica: string,
    awardModelHs: string,
    awardScienceBowl: string,
    awardScienceOlympiad: string,
    awardSolarCup: string,
    awardVexRobotics: string,
    awardTeacherYear: string,
    alternativeEducationType: string,
    altEducationCoLocation: string,
    advancedPlacement: string,
    apCapstone?: string,
    afterSchool: string,
    schoolName: string,
    careerTechnicalEducation: string,
    costCenter: string,
    decathalon: string,
    dlpType: string,
    dlpLanguage: string,
    dlpGrades: string,
    dlp_schools: string,
    gradesOffered: string,
    localDistrict?: string,
    phoneNumber?: string,
    boardDistrict?: string,
    principalName?: string,
    principalContact?: string,
    schoolType?: string,
    cos?: string,
    gtkos?: string,
    ib?: string,
    ibPrimaryGrade?: string,
    infantCare?: string,
    jrotc: string,
    elementaryStartTime?: string,
    elementaryEndTime?: string,
    seniorStartTime?: string,
    seniorEndTime?: string,
    optionsStartTime?: string,
    optionsEndTime?: string,
    linkedLearningType: string,
    locnx: string,
    mentalHealth: string,
    middleStartTime?: string,
    middleEndTime?: string,
    spedPrograms?: string,
    meals?: string,
    choicePrograms?: string,
    magnetName?: string,
    magnetTheme?: string,
    magnet_schools?: string,
    magnetPolicy?: string,
    multiLingual?: string,
    physLocalDistrict?: string,
    sports?: string,
    gearUp?:string,
    gearUpGrade?:string
    wellnessCenter?: string,
    parentCenters?: string,
    sportsGender?: string,
    sportsSeason?: string,
    statePreschool: string,
    btb?: string,
    artsProgramsElem?: string,
    latitude: string;
    longitude: string;
    preferredLocationCode: string;
    pin: {
      location: {
        lat: number,
        lon: number
      }

    },
    pwt_schools: string,
    score: number,
    sasSchools: string,
    maxScore: number
    distance: number;
    website: string,
  },
  cms: {
    mediaLinks?: Media,
    socialLinks?: any[],
    tourDates?: any,
    getToKnowYourSchool?: any
  }

}

export interface Media {
  media1?: MediaObj,
  media2?: MediaObj,
  media3?: MediaObj,
  media4?: MediaObj,
  media5?: MediaObj,
}

export interface MediaObj {
  alt: string,
  base64: {base64:string},
  filename: string,
  type: string,
}

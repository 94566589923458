/**
 * Lausd.Facilities.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MdlCmsSchoolInfo {
    id?: string;
    document?:  {
      sst: any,
      cms: {
        tourDates: {
          description: string
        },
        socialLinks: [{
          provider: string,
          providerId: string
        }],
        getToKnowYourSchool: {
          description: {
            en:string,
            es:string
          }
        },
        mediaLinks: {
          media1: {
            alt: string,
            type: string,
            filename: string,
            base64: string
          },
          media2: {
            alt: string,
            type: string,
            filename: string,
            base64: string
          },
          media3: {
            alt: string,
            type: string,
            filename: string,
            base64: string
          },
          media4: {
            alt: string,
            type: string,
            filename: string,
            base64: string
          },
          media5: {
            alt: string,
            type: string,
            filename: string,
            base64: string

          },
        }
      }
    };
    schoolName?: string;
    version?: string;
    published?: string;
    publishedBy?: string;
    created?: string;
    createdBy?: string;
    comments?: string;
    workflowSessionId?: string;
    modifiedPath?: string;
    description?: string;
    state?: string;
    socialLinkState: string
    tourDatesState: string;
    getToKnowYourSchoolState: string;
    mediaLinkState: string;
    socialLinkWorkflow: string;
    tourDatesWorkflow: string;
    getToKnowYourSchoolWorkflow: string;
    mediaLinkWorkflow: string;
    modified: Date;
    isActive:boolean;

}

